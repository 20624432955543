import React from 'react';

import { useNavigate } from '@hooks/useNavigate';
import { SpecifyPhoneNumberForm } from '@widgets/auth/SpecifyPhoneNumberForm';

import { useSignIn } from '../hooks/useSignIn';
import { CredentialType, useSignInContext } from '../context';
import { SignInNotYetRegistered } from '../SignInNotYetRegistered';

export const SignInPhoneForm = () => {
  const { setIsRestorePassword, setType, setValue } = useSignInContext();

  const [signIn, { errors, isLoading }] = useSignIn();
  const navigate = useNavigate();

  const handleBackButtonClick = () => navigate(-1);

  const handleSignViaEmailClick = () => {
    setType(CredentialType.Email);
    setValue(null);
    setIsRestorePassword(false);
  };

  const onSubmit = async (phoneNumber: string) => {
    const result = await signIn({ type: CredentialType.Phone, value: phoneNumber });

    if (result) {
      setValue(phoneNumber);

      if (!result.hasPassword) {
        setIsRestorePassword(true);
      }
    }
  };

  return (
    <div>
      <SpecifyPhoneNumberForm
        helperText={
          <span onClick={handleSignViaEmailClick} className="text-shamrock cursor-pointer">
            Увiйти через електронну адресу
          </span>
        }
        disabled={isLoading}
        errors={errors}
        onSubmit={onSubmit}
        onBack={handleBackButtonClick}
      />

      <SignInNotYetRegistered className="mt-10" />
    </div>
  );
};

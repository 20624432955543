import React, { createContext, useContext, useState } from 'react';

interface ISignInType {
  type: CredentialType;
  setType: (type: CredentialType) => void;

  value: string | null;
  setValue: (value: string | null) => void;

  isRestorePassword: boolean;
  setIsRestorePassword: (value: boolean) => void;
}

export enum CredentialType {
  Email = 'email',

  Phone = 'phone',
}

const SignInContext = createContext({} as ISignInType);
export const useSignInContext = (): ISignInType => useContext(SignInContext);

export const SignInProvider: React.FC = ({ children }) => {
  const [type, setType] = useState<CredentialType>(CredentialType.Phone);
  const [value, setValue] = useState<string | null>(null);
  const [isRestorePassword, setIsRestorePassword] = useState<boolean>(false);

  const contextValue = {
    type,
    setType,

    value,
    setValue,

    isRestorePassword,
    setIsRestorePassword,
  };

  return <SignInContext.Provider value={contextValue}>{children}</SignInContext.Provider>;
};

import { FIELD_PASSWORD } from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import * as yup from 'yup';

export const validationSchemaPassVerify = yup.object().shape({
  [FIELD_PASSWORD]: yup
    .string()
    .trim()
    .required('errors.required')
    .min(6, 'errors.too-short')
    .max(20, 'errors.too-long'),
});

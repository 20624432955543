import * as React from 'react';

import { CredentialType, useSignInContext } from '../context';

import { SignInEmailForm } from '../email-form';
import { SignInPhoneForm } from '../phone-form';
import { VerifyPasswordForm } from '../verify-form/VerifyPasswordForm';

export const SignInForm = () => {
  const { type, value } = useSignInContext();

  if (type && value) {
    return <VerifyPasswordForm />;
  }

  if (type === CredentialType.Email) {
    return <SignInEmailForm />;
  }
  return <SignInPhoneForm />;
};

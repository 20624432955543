import * as React from 'react';

import { SignInForm } from '@widgets/sign-in/form';
import { SignInTitle } from '@widgets/sign-in/title';
import { SignInProvider } from '@widgets/sign-in/context';
import { INITIAL_PATHNAME } from '@shared/constants';

import { OauthProviders } from '@widgets/auth/OauthProviders';
import { UserType } from '@shared/enums';

const SignIn = () => {
  React.useEffect(() => {
    return () => {
      localStorage.removeItem(INITIAL_PATHNAME);
    };
  }, []);

  return (
    <div className="px-4 pt-6 pb-12 lg:pt-12">
      <SignInProvider>
        <SignInTitle />
        <OauthProviders type={UserType.InNeed} />
        <SignInForm />
      </SignInProvider>
    </div>
  );
};

export default SignIn;

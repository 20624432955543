import { yupResolver } from '@hookform/resolvers/yup';
import useLocalStorage from '@hooks/useLocalStorage';
import { useLogIn } from '@hooks/useLogIn';
import { useNavigate } from '@hooks/useNavigate';
import { INITIAL_PATHNAME } from '@shared/constants';
import { useCabinetNavigate } from '@shared/hooks/useCabinetNavigate';
import { Button } from '@shared/ui/buttons/Button';
import { ErrorList } from '@shared/ui/ErrorList';
import { TextField } from '@shared/ui/TextField';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useRestorePasswordMutation, useSignInVerifyMutation } from '@store/api/user.api';
import { FIELD_PASSWORD } from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSignInContext } from '../context';
import { validationSchemaPassVerify } from './constants';
import { UserType } from '@shared/enums';

const initialFormValues = {
  [FIELD_PASSWORD]: '',
};

type VerifyPasswordFormValues = {
  password: string;
};

export const VerifyPasswordForm: React.FC = () => {
  const { type, setValue, value, isRestorePassword, setIsRestorePassword } = useSignInContext();

  const methods = useForm<VerifyPasswordFormValues>({
    resolver: yupResolver(validationSchemaPassVerify),
    defaultValues: initialFormValues,
  });

  const [signInVerify] = useSignInVerifyMutation();
  const [restorePassword, { isLoading: isLoadingRestorePass }] = useRestorePasswordMutation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [initialPathname] = useLocalStorage(INITIAL_PATHNAME);

  const cabinetNavigate = useCabinetNavigate();

  const logIn = useLogIn();

  const {
    handleSubmit,
    formState: { isSubmitting, isValidating },
  } = methods;

  const handleBackButtonClick = () => {
    setValue(null);
    setIsRestorePassword(false);
  };

  const onSubmit = handleSubmit(async ({ password }) => {
    try {
      if (!value) {
        return;
      }

      setApiErrors([]);

      const { token, payload } = await signInVerify({ type, value, password }).unwrap();
      logIn(payload, token);
      initialPathname ? navigate(initialPathname) : cabinetNavigate(payload.type || UserType.InNeed);
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
    }
  });

  const handleRestorePassPhoneClick = async () => {
    if (isLoadingRestorePass || !value) return;

    try {
      await restorePassword({ type, value }).unwrap();

      setIsRestorePassword(true);
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="max-w-[528px] mx-auto">
        <div className="sign-up-form-card">
          <TextField id={FIELD_PASSWORD} type="password" label="password" />

          <ErrorList errors={apiErrors} className="mt-2" />
        </div>

        <div className="flex flex-col items-center mt-8">
          <Button as="button" variant="primary" type="submit" disabled={isSubmitting || isValidating}>
            {t('continue')}
          </Button>

          <Button as="button" variant="secondary" type="button" className="mt-2" onClick={handleBackButtonClick}>
            {t('back')}
          </Button>
        </div>

        {!isRestorePassword && (
          <div className="mt-8 text-center text-xs">
            <span className="whitespace-nowrap">{t('forgot-password')}</span>{' '}
            <span onClick={handleRestorePassPhoneClick} className="text-shamrock cursor-pointer">
              {t('restore')}
            </span>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

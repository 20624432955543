import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSignInMutation } from '@store/api/user.api';

import { SignInRequest } from '@store/api/types';
import { isApiError, translateApiError } from '@shared/utils/errors';

export const useSignIn = () => {
  const { t } = useTranslation();

  const [mutation, { isLoading }] = useSignInMutation();
  const [errors, setErrors] = useState<string[]>([]);

  const signIn = async (options: SignInRequest) => {
    try {
      return await mutation(options).unwrap();
    } catch (err) {
      // @ts-ignore
      if (err.originalStatus === 429) {
        // @ts-ignore
        setErrors([t(err.data)]);
      }

      if (isApiError(err)) {
        setErrors(translateApiError(err, t));
      }

      return null;
    }
  };

  return [signIn, { isLoading, errors }] as const;
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Element } from '@shared/interfaces/element.interface';
import { GenericLink } from '@shared/ui/GenericLink';

interface SignInNotYetRegisteredProps extends Element {}

export const SignInNotYetRegistered: React.FC<SignInNotYetRegisteredProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx('flex flex-col md:flex-row text-center justify-center text-xs', className)}>
      <span className="whitespace-pre">{t('not-yet-registered') + ' - '}</span>
      <GenericLink type="page" className="text-shamrock hover:underline" to="/auth/sign-up">
        {t('sign-up')}
      </GenericLink>
    </div>
  );
};

import React from 'react';

import { useNavigate } from '@hooks/useNavigate';
import { SpecifyEmailForm } from '@widgets/auth/SpecifyEmailForm';

import { useSignIn } from '../hooks/useSignIn';
import { CredentialType, useSignInContext } from '../context';
import { SignInNotYetRegistered } from '../SignInNotYetRegistered';

export const SignInEmailForm = () => {
  const { type, setType, setValue, setIsRestorePassword } = useSignInContext();
  const [signIn, { isLoading, errors }] = useSignIn();
  const navigate = useNavigate();

  const handleSignViaPhoneClick = () => {
    setType(CredentialType.Phone);
    setValue(null);
    setIsRestorePassword(false);
  };

  const onEmailSubmit = async (email: string) => {
    const result = await signIn({ type, value: email });

    if (result) {
      setValue(email);

      if (!result.hasPassword) {
        setIsRestorePassword(true);
      }
    }
  };

  const onBack = () => navigate(-1);

  return (
    <div>
      <SpecifyEmailForm
        helperText={
          <span onClick={handleSignViaPhoneClick} className="text-shamrock cursor-pointer">
            Увiйти через номер телефона
          </span>
        }
        disabled={isLoading}
        errors={errors}
        onSubmit={onEmailSubmit}
        onBack={onBack}
      />

      <SignInNotYetRegistered className="mt-10" />
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { TextField } from '@shared/ui/TextField';
import { ErrorList } from '@shared/ui/ErrorList';

import { Button } from '@shared/ui/buttons/Button';

const FIELD_EMAIL = 'email';

const validationSchema = yup.object().shape({
  [FIELD_EMAIL]: yup.string().trim().required('errors.required').email('errors.invalid-email'),
});

const initialFormValues = {
  [FIELD_EMAIL]: '',
};

interface SpecifyEmailFormProps {
  disabled?: boolean;

  errors?: string[];

  helperText?: string | React.ReactElement;

  onSubmit: (email: string) => void;

  onBack: () => void;
}

export const SpecifyEmailForm: React.FC<SpecifyEmailFormProps> = (props) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting, isValidating },
  } = methods;

  const onSubmit = handleSubmit(({ email }) => {
    props.onSubmit(email);
  });

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className="max-w-[528px] mx-auto">
          <div className="px-6 mt-8 md:mt-12 pt-10 pb-14 md:px-14 md:pb-16 rounded-lg border-2 border-hawkes-blue bg-white mt-0">
            <TextField
              className="h-[50px]"
              id={FIELD_EMAIL}
              helperText={props.errors && props.errors.length > 0 ? '' : props.helperText}
              label="email"
              autoFocus
            />

            <ErrorList errors={props.errors || []} className="mt-2" />
          </div>

          <div className="flex flex-col items-center mt-8">
            <Button
              as="button"
              variant="primary"
              type="submit"
              disabled={isSubmitting || isValidating || props.disabled}
            >
              {t('continue')}
            </Button>

            <Button as="button" variant="secondary" type="button" className="mt-2" onClick={props.onBack}>
              {t('back')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

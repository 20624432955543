import React from 'react';

import { Button } from '@shared/ui/buttons/Button';
import { redirect } from '@shared/utils/path';
import { UserType } from '@shared/enums';

import { useLazyGetIdGovUaAuthUrlQuery } from '@store/api/oauth.api';

import idGovUaIcon from '@assets/icons/id-gov-ua-light.svg';

interface IdGovUaButtonProps {
  type?: UserType.InNeed | UserType.Volunteer;

  text: string;

  disabled?: boolean;
}

export const IdGovUaButton: React.FC<IdGovUaButtonProps> = ({ text, type = UserType.InNeed, disabled }) => {
  const [getIdGovUaAuthUrl, { isLoading, isFetching }] = useLazyGetIdGovUaAuthUrlQuery();

  const onButtonClick = async () => {
    try {
      const { url } = await getIdGovUaAuthUrl({ type }).unwrap();

      redirect(url);
    } catch (err) {
      // TODO: handle errors
      console.log('IdGovUaConnect', err);
    }
  };

  return (
    <Button
      as="button"
      size="large"
      className="flex items-center space-x-2 mt-12 text-xs md:text-base"
      onClick={onButtonClick}
      disabled={isLoading || isFetching || disabled}
    >
      <span>{text}</span>
      <img src={idGovUaIcon} width="80" height="10" className="object-contain select-none" />
    </Button>
  );
};

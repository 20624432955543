import React from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';

import { CredentialType, useSignInContext } from '../context';

export const SignInTitle = () => {
  const { type, value, isRestorePassword } = useSignInContext();
  const { t } = useTranslation();
  const iconOptimized = useGetIconOptimized('locker');

  return (
    <div className="flex flex-col items-center  md:flex-col md:justify-center">
      <GatsbyImage image={getImage(iconOptimized)!} alt="icon" className="w-[48px] h-auto md:w-[48px]" />
      <div className="ml-4 md:ml-0 md:mt-[18px]">
        <h1 className="text-xl md:text-2xl">{value ? t('unauthenticated') : t('signin')}</h1>
      </div>

      <div className="font-light text-center">
        {isRestorePassword && (
          <p
            className="mt-4"
            dangerouslySetInnerHTML={{
              __html: t(type === CredentialType.Email ? 'enter-pass-from-email' : 'enter-pass-from-sms'),
            }}
          />
        )}
      </div>
    </div>
  );
};
